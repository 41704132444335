import React, { useState, useEffect } from 'react';
import {
  LineChart, Line, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { FaHome, FaLeaf, FaCog } from 'react-icons/fa';
import { Oval } from 'react-loader-spinner';
import './Dashboard.css';
import './graficasGrandes.css';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import StatCard from './StatCard';
import HighlightedCard from './HighlightedCard';
import SessionsChart from './SessionsChart';
import PageViewsBarChart from './PageViewsBarChart';
import RecyclingBarChart from './graficaBarras';
import PieDashboardComponent from './pieDashboardComponent';
import Typography from '@mui/material/Typography';

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [conteinerStatus, setConteinerStatus] = useState([]);
  const [recyclinginstancesToday, setRecyclingInstancesToday] = useState([]);
  const [recyclinginstancesMonth, setRecyclingInstancesMonth] = useState([]);
  const [recyclinginstancesYear, setRecyclingInstancesYear] = useState([]);
  const [totalWasteData, setTotalWasteData] = useState([]);
  const [typeWasteData, setTypeWasteData] = useState([]);
  const [carbonEmissionsData, setCarbonEmissionsData] = useState([]);
  const [totalEmissionsSaved, setTotalEmissionsSaved] = useState(0);
  const [emissionsSavedLastMonth, setEmissionsSavedLastMonth] = useState(0);
  const [totalWeightRecycled, setTotalWeightRecycled] = useState(0);
  const [peopleRecycled, setPeopleRecycled] = useState(0);
  const [kgPerDay, setKgPerDay] = useState(0);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();


  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');
      console.log(token);
  
      if (!token) {
        console.error('No token found');
        return;
      }
  
      try {
        const [simpleDataRes, containerStatusRes, recyclingTodayRes, recyclingMonthRes, recyclingYearRes] = await Promise.all([
          fetch('https://api.classify-tech.com/user/simpleData', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              Authorization: `Bearer ${token}`,
            }
          }),
          fetch('https://api.classify-tech.com/sensors/lastValue', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              Authorization: `Bearer ${token}`,
            }
          }),
          fetch('https://api.classify-tech.com/sensors/recyclinginstancesToday', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              Authorization: `Bearer ${token}`,
            }
          }),
          fetch('https://api.classify-tech.com/sensors/recyclingInstancesmonth', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              Authorization: `Bearer ${token}`,
            }
          }),
          fetch('https://api.classify-tech.com/sensors/recyclingInstancesYear', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              Authorization: `Bearer ${token}`,
            }
          })
        ]);
  
        // Verificar que todas las respuestas sean exitosas
        if (!simpleDataRes.ok || !containerStatusRes.ok || !recyclingTodayRes.ok || !recyclingMonthRes.ok) {
          throw new Error('One or more API calls failed');
        }
  
        // Convertir todas las respuestas a JSON
        const [simpleData, containerStatus, recyclingToday, recyclingMonth, recyclingYear] = await Promise.all([
          simpleDataRes.json(),
          containerStatusRes.json(),
          recyclingTodayRes.json(),
          recyclingMonthRes.json(),
          recyclingYearRes.json()
        ]);
        console.table("dentro de fetch, recyclingMonth: " , recyclingMonth);
  
        setData(simpleData.data || []);
        setConteinerStatus(containerStatus.data || []);
        setRecyclingInstancesToday(recyclingToday.data || []);
        setRecyclingInstancesMonth(recyclingMonth.data || []);
        setRecyclingInstancesYear(recyclingYear.data || []);
        
        setLoading(false); // Dejar de mostrar el loading una vez que todos los datos se obtienen
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
        setLoading(false); // Asegurar que loading se detiene en caso de error
      }
      finally{
        setLoading(false); // Esto debe ir después de que los datos hayan sido correctamente procesados.
      }
    };

    const generateRecyclingInstancesByDay = (data) => {
      if (!data || !data.instancesByDay) return [];
      const instancesByDay = data.instancesByDay || {}; // Asegurar que `instancesByDay` existe
      console.log("Instances by day:", instancesByDay);
    
      const now = new Date();
      const currentYear = now.getFullYear();
      const currentMonth = now.getMonth() + 1; // Mes actual
      const daysInMonth = new Date(currentYear, currentMonth, 0).getDate(); // Días en el mes actual
    
      // Array para almacenar instancias de reciclaje por día, inicializado a 0
      const instanciasReciclajeMes = Array(daysInMonth).fill(0);
    
      // Iterar sobre las fechas (claves de `instancesByDay`)
      Object.keys(instancesByDay).forEach((dateString) => {
        const date = new Date(dateString); // Convertir la cadena de fecha a un objeto Date
        const day = date.getDate(); // Obtener el día del mes
        const instances = instancesByDay[dateString] || 0; // Obtener las instancias para esa fecha
    
        // Verificar que el día esté dentro del rango válido del mes actual
        if (day > 0 && day <= daysInMonth) {
          instanciasReciclajeMes[day - 1] = instances; // Asignar instancias al día correspondiente
        }
      });
    
      console.log("Array instancias por día:", instanciasReciclajeMes); // Verificar resultado final
      return instanciasReciclajeMes;
    };
  
    fetchAllData();
    generateRecyclingInstancesByDay()
  }, []);  
  

  const calculateCarbonEmissions = (wasteId) => {
    const emissionsPerWasteType = {
      1: 0.1, // plástico
      2: 0.2, // vidrio
      3: 0.2, // papel
      4: 0.3, // aluminio
    };
    return emissionsPerWasteType[wasteId] || 0;
  };

  const calculateWeight = (wasteId) => {
    const weightPerWasteType = {
      1: 0.2, // plástico (kg)
      2: 0.3, // vidrio (kg)
      3: 0.2, // papel (kg)
      4: 0.8, // aluminio (kg)
    };
    return weightPerWasteType[wasteId] || 0;
  };

  const filterRecentMonths = (data) => {
    const sortedData = data.sort((a, b) => new Date(b.month) - new Date(a.month));
    return sortedData.slice(0, 6).reverse();
  };

  const roundNumber = (num) => Math.round(num);
  

  const generateRecyclingInstancesByDay = (data) => {
    if (!data || !data.instancesByDay) return [];
    const instancesByDay = data.instancesByDay || {}; // Asegurar que `instancesByDay` existe
    console.log("Instances by day:", instancesByDay);
  
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1; // Mes actual
    const daysInMonth = new Date(currentYear, currentMonth, 0).getDate(); // Días en el mes actual
  
    // Array para almacenar instancias de reciclaje por día, inicializado a 0
    const instanciasReciclajeMes = Array(daysInMonth).fill(0);
  
    // Iterar sobre las fechas (claves de `instancesByDay`)
    Object.keys(instancesByDay).forEach((dateString) => {
      const date = new Date(dateString); // Convertir la cadena de fecha a un objeto Date
      const day = date.getDate(); // Obtener el día del mes
      const instances = instancesByDay[dateString] || 0; // Obtener las instancias para esa fecha
  
      // Verificar que el día esté dentro del rango válido del mes actual
      if (day > 0 && day <= daysInMonth) {
        instanciasReciclajeMes[day - 1] = instances; // Asignar instancias al día correspondiente
      }
    });
  
    console.log("Array instancias por día:", instanciasReciclajeMes); // Verificar resultado final
    return instanciasReciclajeMes;
  };

  const generateRecyclingWeightAndCarbonByDay = (data) => {
    // Comprobamos que 'instancesByDayCategory' exista y sea un objeto
    if (!data || !data.instancesByDayCategory || typeof data.instancesByDayCategory !== 'object') {
      return { 
        pesoRecicladoPorDia: [], 
        pesoTotalReciclado: 0, 
        emisionesCarbonoPorDia: [], 
        emisionesCarbonoTotales: 0 
      };
    }
  
    console.log("Instances by day category: ", data.instancesByDayCategory);
  
    const instancesByDayCategory = data.instancesByDayCategory;
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1; // Los meses en JavaScript comienzan desde 0
    const daysInMonth = new Date(currentYear, currentMonth, 0).getDate(); // Obtener el número de días en el mes actual
  
    // Crear arrays con el tamaño del número de días del mes, inicializados a 0
    const pesoRecicladoPorDia = Array(daysInMonth).fill(0);
    const emisionesCarbonoPorDia = Array(daysInMonth).fill(0);
  
    // Variables para almacenar los totales en el mes
    let pesoTotalReciclado = 0;
    let emisionesCarbonoTotales = 0;
  
    // Mapear tipos de residuos a sus identificadores para las funciones
    const wasteTypeIds = {
      plastic: 1,
      glass: 2,
      carton_paper: 3,
      aluminium: 4,
      organic: 5 // Añadimos 'organic' en el mapeo
    };
  
    // Iterar sobre cada día presente en instancesByDayCategory
    Object.keys(instancesByDayCategory).forEach((date) => {
      const day = new Date(date).getDate(); // Obtener el día del mes a partir de la fecha
      const categories = instancesByDayCategory[date]; // Obtener las categorías de residuos para ese día
  
      if (categories && typeof categories === 'object') {
        let totalWeightForDay = 0;
        let totalEmissionsForDay = 0;
  
        // Iterar sobre cada categoría y calcular el peso reciclado y las emisiones de carbono
        Object.keys(categories).forEach((category) => {
          const wasteId = wasteTypeIds[category]; // Obtener el id del tipo de residuo
          const instances = categories[category]; // Cantidad de instancias recicladas de esa categoría
  
          // Verificar que exista una cantidad de instancias
          if (instances && wasteId) {
            const weightForCategory = calculateWeight(wasteId) * instances; // Calcular el peso reciclado
            const emissionsForCategory = calculateCarbonEmissions(wasteId) * instances; // Calcular las emisiones ahorradas
  
            totalWeightForDay += weightForCategory; // Sumar el peso reciclado para ese día
            totalEmissionsForDay += emissionsForCategory; // Sumar las emisiones ahorradas para ese día
          }
        });
  
        // Asignar los valores calculados al array en la posición correspondiente al día
        pesoRecicladoPorDia[day - 1] = totalWeightForDay;
        emisionesCarbonoPorDia[day - 1] = totalEmissionsForDay;
  
        // Acumular los totales en el mes
        pesoTotalReciclado += totalWeightForDay;
        emisionesCarbonoTotales += totalEmissionsForDay;
      }
    });
  
    console.log("Peso reciclado por día: ", pesoRecicladoPorDia);
    console.log("Emisiones de carbono ahorradas por día: ", emisionesCarbonoPorDia);
    console.log("Peso total reciclado en el mes: ", pesoTotalReciclado);
    console.log("Emisiones de carbono totales ahorradas en el mes: ", emisionesCarbonoTotales);
  
    // Retornar el peso por día, el total del mes, las emisiones por día y las emisiones totales
    return {
      pesoRecicladoPorDia,
      pesoTotalReciclado,
      emisionesCarbonoPorDia,
      emisionesCarbonoTotales
    };
  };

  const generateRecyclingInstancesByCategoryPerDay = (data) => {
    // Verificar que 'instancesByDayCategory' exista y sea un objeto
    if (!data || !data.instancesByDayCategory || typeof data.instancesByDayCategory !== 'object') {
      return {
        plastic: [],
        glass: [],
        carton_paper: [],
        aluminium: [],
        organic: []
      };
    }
  
    const instancesByDayCategory = data.instancesByDayCategory;
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1; // Los meses en JavaScript comienzan desde 0
    const daysInMonth = new Date(currentYear, currentMonth, 0).getDate(); // Obtener el número de días en el mes actual
  
    // Inicializar arrays para cada categoría con el tamaño del número de días del mes, inicializados a 0
    const instancesPerCategory = {
      plastic: Array(daysInMonth).fill(0),
      glass: Array(daysInMonth).fill(0),
      carton_paper: Array(daysInMonth).fill(0),
      aluminium: Array(daysInMonth).fill(0),
      organic: Array(daysInMonth).fill(0)
    };
  
    // Iterar sobre cada día en 'instancesByDayCategory'
    Object.keys(instancesByDayCategory).forEach((date) => {
      const day = new Date(date).getDate(); // Obtener el día del mes a partir de la fecha
      const categories = instancesByDayCategory[date]; // Obtener las categorías de residuos para ese día
  
      if (categories && typeof categories === 'object') {
        // Iterar sobre cada categoría y asignar el número de instancias para ese día
        Object.keys(categories).forEach((category) => {
          const instances = categories[category]; // Cantidad de instancias recicladas de esa categoría
          if (instancesPerCategory[category] && instances !== undefined) {
            instancesPerCategory[category][day - 1] = instances; // Asignar el valor al array correspondiente
          }
        });
      }
    });
  
    console.log("Instancias recicladas por categoría por día: ", instancesPerCategory);
  
    // Devolver el objeto con los arrays por categoría
    return instancesPerCategory;
  };



  const generateRecyclingInstancesByYear = (data) => {
    // Verificar que los datos existen y que `instancesByMonthCategory` está presente
    if (!data || !data.instancesByMonthCategory) return [];
  
    const instancesByMonthCategory = data.instancesByMonthCategory || []; // Asegurar que `instancesByMonthCategory` existe
    console.log("Instances by month and category:", instancesByMonthCategory);
  
    // Asegurar que `instancesByMonthCategory` tiene 12 meses (uno por cada mes del año)
    const monthsInYear = 12;
    const categories = ["plastic", "carton_paper", "organic", "aluminium", "glass"];
    
    // Inicializar array de instancias recicladas por año con arrays por cada mes, y cada mes con valores 0 para cada categoría
    const instanciasReciclajeAño = Array.from({ length: monthsInYear }, () => Array(categories.length).fill(0));
  
    // Iterar sobre los meses en `instancesByMonthCategory`
    instancesByMonthCategory.forEach((monthData, index) => {
      if (index >= 0 && index < monthsInYear) {
        // Por cada mes, iterar sobre las categorías
        categories.forEach((category, catIndex) => {
          const instances = monthData[category] || 0; // Obtener las instancias para esa categoría en ese mes
          instanciasReciclajeAño[index][catIndex] = instances; // Asignar las instancias a la posición correspondiente
        });
      }
    });
  
    console.log("Array instancias por año:", instanciasReciclajeAño); // Verificar el resultado final
    return instanciasReciclajeAño;
  };

  function sumarNumeros(numeros) {
    return numeros.reduce((acumulador, numero) => acumulador + numero, 0);
  }
  
  const {pesoRecicladoPorDia, pesoTotalReciclado, emisionesCarbonoPorDia, emisionesCarbonoTotales} = generateRecyclingWeightAndCarbonByDay(recyclinginstancesMonth);
  const instanciasReciclajeMes2 = generateRecyclingInstancesByDay(recyclinginstancesMonth);
  const instanciasReciclajeporCategoria = generateRecyclingInstancesByCategoryPerDay(recyclinginstancesMonth);

  const usosPorRegistradosPorMes = [2234, 3872, 2998, 4125, 3357, 2789, 2998];

  const usosPorNoRegistradosPorMes = [3098, 4215, 2384, 2101, 4752, 3593, 2384];

  const dataResiduosGeneral = [
    [200, 150, 100, 80, 50], // Enero: Plástico, Papel y Cartón, Orgánicos, Aluminio, Vidrio
    [180, 130, 110, 60, 70], // Febrero
    [200, 150, 150, 80, 70], // Marzo
    [220, 130, 160, 80, 90], // Abril
    [230, 110, 220, 75, 110], // Mayo
    [200, 110, 170, 90, 120], // Junio
    [190, 90, 150, 100, 115], // Julio
    [230, 150, 120, 80, 130], // Agosto
    [240, 140, 150, 110, 150], // Setiembre
    [230, 130, 110, 60, 70], // Octubre
    [200, 150, 120, 100, 150], // Noviembre 
    [240, 160, 110, 120, 160], // Diciembre
  ];

  const dataReciduosRecicladosAño = generateRecyclingInstancesByYear(recyclinginstancesYear);

  // Valores de ejemplo para las categorías
  const categories = [
    { name: 'Papel', value:conteinerStatus['1000']?.carton_paper , color: '#7DA2CF' },
    { name: 'Plástico', value: conteinerStatus['1000']?.plastic || 0, color: '#BBFF42' },
    { name: 'Orgánico', value: conteinerStatus['1000']?.organic || 0, color: '#193863' },
    { name: 'Vidrio', value: conteinerStatus['1000']?.glass || 0, color: '#F4F4F4' },
    { name: 'Metal', value: conteinerStatus['1000']?.aluminium || 0, color: '#001600' },
  ];

  return (
    // Contenedor principal con fondo gris claro
    <Box sx={{ backgroundColor: '#002400', minHeight: '100vh' , marginLeft: '185px'}}>
      <Grid container spacing={2}> {/* Controlar el espacio entre los elementos */}
        
        {/* Primera fila - StatCards */}
        <Grid item xs={12} md={3}>
          <Box sx={{backgroundColor: '#002400', borderRadius: 2}}>
            <StatCard
              title="Emisiones de Carbono Ahorradas"
              value={roundNumber(emisionesCarbonoTotales) + "kg"}
              interval="Últimos 30 días"
              trend="up"
              data = {emisionesCarbonoPorDia}
              month= {Number(new Date().getMonth() + 1)}
              year={Number(new Date().getFullYear())}
            />
          </Box>
        </Grid>
  
        <Grid item xs={12} md={3}>
          <Box sx={{ backgroundColor: '#002400', borderRadius: 2 }}>
            {loading ? (  // Mostrar CircularProgress si está cargando
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <CircularProgress color="inherit" />
              </Box>
            ) : (
              <StatCard
                title="Peso Reciclado"
                value={roundNumber(pesoTotalReciclado) + "kg"}
                interval="Últimos 30 días"
                trend="up"
                data={pesoRecicladoPorDia}
                month={Number(new Date().getMonth() + 1)}
                year={Number(new Date().getFullYear())}
              />
            )}
            {error && <p style={{ color: 'red' }}>{error}</p>} {/* Mostrar error si existe */}
          </Box>
        </Grid>
  
        <Grid item xs={12} md={3}>
          <Box sx={{ backgroundColor: '#002400', borderRadius: 2 }}>
            {loading ? (  // Mostrar CircularProgress si está cargando
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <CircularProgress color="inherit" />
              </Box>
            ) : (
              <StatCard
                title="Instancias de reciclaje"
                value={recyclinginstancesMonth.totalInstances }
                interval="Últimos 30 días"
                trend="up"
                data={instanciasReciclajeMes2}
                month={Number(new Date().getMonth() + 1)}
                year={Number(new Date().getFullYear())}
              />
            )}
            {error && <p style={{ color: 'red' }}>{error}</p>} {/* Mostrar error si existe */}
          </Box>
        </Grid>

        {/* Get insights */}
        <Grid item xs={12} md={3}>
          <Box sx={{backgroundColor: '#002400', borderRadius: 2 }}>
            <HighlightedCard data={totalWasteData} />
          </Box>
        </Grid>

        {/* Gráfica - Gráfica de Emisiones de Carbono */}
        <Grid item xs={12} md={6}>
          <Box sx={{backgroundColor: '#002400', borderRadius: 2, className:"white-axes"}}>
            <SessionsChart 
              title = "Cantidad de residuos"
              value = {sumarNumeros(instanciasReciclajeporCategoria.plastic) + sumarNumeros(instanciasReciclajeporCategoria.organic)+ sumarNumeros(instanciasReciclajeporCategoria.carton_paper)}
              subtitle = "Unidades de residuos reciclados en los últimos 30 días"
              datosPlastico = {instanciasReciclajeporCategoria.plastic}
              datosOrganicos = {instanciasReciclajeporCategoria.organic}
              datosPapelCarton = {instanciasReciclajeporCategoria.carton_paper}
              month= {Number(new Date().getMonth() + 1)}
              year={Number(new Date().getFullYear())}
            />
          </Box>
        </Grid>
  
        {/* Gráfica - Resumen de Residuos por Material */}
        <Grid item xs={12} md={6}>
          <Box sx={{backgroundColor: '#002400', borderRadius: 2 }}>
            <PageViewsBarChart 
              data={typeWasteData} 
              title = "Usuarios de la maquina"
              value = "1.3M"
              subtitle = "Comparación entre usuarios registrados y no registrados"
              usosPorRegistrados = {usosPorRegistradosPorMes}
              usosPorNoRegistrados = {usosPorNoRegistradosPorMes}
            />
          </Box>
        </Grid>

        {/* Gráfica - Resumen de Residuos por Material */}
        <Grid item xs={12} md={12}>
          <Box sx={{backgroundColor: '#002400', borderRadius: 2 }}>
          <RecyclingBarChart
            title="Residuos Reciclados"
            subtitle="Datos de reciclaje de los últimos 12 meses para cada categoria"
            data={dataReciduosRecicladosAño}
            sx={{ height: '100%' }}
          />
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box sx={{ backgroundColor: '#002400', borderRadius: 2, position: 'relative', height: '300px',paddingBottom: 5 }}>
          {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress color="inherit" />
          </Box>
          )  : (
          <PieDashboardComponent
            title="Estado actual de los contenedores"
            categories={categories}
          />
          )}
          </Box>
            {error && <p style={{ color: 'red' }}>{error}</p>} {/* Muestra el error si existe */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
